<template>
  <div>
    <section class="parallax-container">
      <video
        ref="videoBg"
        class="bg-video"
        loop
        muted
        preload="auto"
        poster="../assets/clouds-bg.png"
      ></video>
    </section>
    <section class="main-page-container letter-spacing-032">
      <div
        class="
          main-banner-container
          letter-spacing-05
          font-sora
          relative-parent
          flex-row flex-align-center
        "
      >
        <div class="container-900 flex-row flex-dir-col flex-align-center">
          <img
            src="../assets/main-logo.svg"
            alt="Based in Sweden logo"
            class="margin-b-40 logo-img"
          />
          <div class="f-20 fw-100 margin-b-30 centered">
            SÄKRA MOLNTJÄNSTER - I SVERIGE
          </div>
          <div class="head-text fw-500 centered">
            Based in Sweden är en kvalitetsmärkning för svenska molnleverantörer
            som lagrar sin data i Sverige.
          </div>
        </div>
      </div>
      <div
        class="
          semi-dark-blue-bg
          padding-top-bottom-40 padding-side-10
          centered
          f-25
          fw-600
          letter-spacing-05
          font-sora
        "
      >
        Välj svenskt - för säkerhets skull.
      </div>
      <div class="flex-row">
        <div
          class="
            flex-50 flex-row flex-dir-col flex-align-center
            dark-blue-bg
            padding-top-bottom-100 padding-side-20
          "
        >
          <img
            src="../assets/cancel.svg"
            class="margin-b-40 cancel-accept-img"
            alt="cross"
          />
          <div class="container-450 line-height-32">
            Företag och myndigheter som hanterar personuppgifter,
            affärshemligheter och annan känslig information via utländska
            molntjänster (t ex i mejlklienter eller videomötesappar ägda av
            Google, Amazon eller Microsoft) utsätter både kunder och medborgare
            för allvarliga säkerhetsrisker.
          </div>
        </div>
        <div
          class="
            flex-50 flex-row flex-dir-col flex-align-center
            light-blue-bg
            darken-blue-text
            padding-top-bottom-100 padding-side-20
          "
        >
          <img src="../assets/done.svg" class="margin-b-40 cancel-accept-img" alt="check" />
          <div class="container-450 line-height-32">
            Molnleverantörer som är Based in Sweden kan erbjuda säkra
            molntjänster med skydd från övervakning och avlyssning.
          </div>
        </div>
      </div>
      <div
        class="
          semi-dark-blue-bg
          padding-top-bottom-40 padding-side-10
          centered
          f-25
          fw-600
          letter-spacing-05
          font-sora
        "
      >
        Varför är svenska molnleverantörer säkrare än andra?
      </div>
      <div class="white-bg">
        <div class="advantages-block" @scroll="onscroll" ref="advBlock">
          <div class="container-1200 flex-row">
            <div
              class="
                flex-33 flex-row flex-dir-col flex-align-center
                padding-side-20
                margin-b-30
              "
            >
              <img
                src="../assets/advantages-1.svg"
                class="advantages-icon margin-b-30"
                alt="Svenska säkerhetslagar"
              />
              <div class="font-sora f-18 margin-b-30">
                Svenska säkerhetslagar
              </div>
              <div>
                Svenska molnleverantörer är skyddade av svensk grundlag, som
                bland annat förbjuder avlyssning utan domstolsbeslut.
              </div>
            </div>
            <div
              class="
                flex-33 flex-row flex-dir-col flex-align-center
                padding-side-20
                margin-b-30
              "
            >
              <img
                src="../assets/advantages-2.svg"
                class="advantages-icon margin-b-30"
                alt="Ingen signalspaning"
              />
              <div class="font-sora f-18 margin-b-30">Ingen signalspaning</div>
              <div>
                När all infrastruktur finns på svensk mark skickas ingen
                datatrafik utanför Sveriges gränser, och kan därmed inte
                avlyssnas av svenska FRA eller utländska motsvarigheter.
              </div>
            </div>
            <div
              class="
                flex-33 flex-row flex-dir-col flex-align-center
                padding-side-20
                margin-b-30
              "
            >
              <img
                src="../assets/advantages-3.svg"
                class="advantages-icon margin-b-30"
                alt="Inget Cloud Act"
              />
              <div class="font-sora f-18 margin-b-30">Inget Cloud Act</div>
              <div>
                Svenska molnleverantörer omfattas inte av lagen Cloud Act som
                ger amerikanska myndigheter rätt att begära ut data från
                amerikanska molnleverantörer - även om deras datahallar ligger i
                Sverige.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          semi-dark-blue-bg
          padding-top-bottom-40 padding-side-10
          centered
          f-25
          fw-600
          letter-spacing-05
          font-sora
        "
      >
        Vill du vara med?
      </div>
      <div class="flex-row line-height-32">
        <div class="flex-50 join-us-logo-bg relative-parent">
          <div
            class="
              inner-layer
              darken-blue-text
              flex-row flex-dir-col flex-align-center
              join-us-inner
            "
          >
            <div class="container-600">
              <div class="margin-b-30 fw-600">
                Based in Sweden är en kvalitetsmärkning för molnleverantörer med
                svenskägd och svensklagrad data, som gör det lättare att välja
                säkra molntjänster. Det är kostnadsfritt att vara med i Based in
                Sweden, men för att erhålla märkningen måste ditt företag
                uppfylla tre kriterier:
              </div>
              <ol>
                <li>Företaget ska tillhandahålla molnbaserade tjänster.</li>
                <li>Företaget ska vara svenskägt och registrerat i Sverige.</li>
                <li>Företagets IT-infrastruktur ska finnas på svensk mark.</li>
              </ol>
            </div>
          </div>
          <div class="yellow-arrow-container">
            <img class="yellow-arrow" src="../assets/yellow-arrow.png" alt="" />
          </div>
        </div>
        <div
          class="
            flex-50 flex-row flex-dir-col flex-align-center
            join-us-inner
            dark-blue-bg
          "
        >
          <div class="container-600">
            <div class="margin-b-30">
              Skicka en ansökan till info@basedinsweden.se för att ansluta ditt
              företag till Based in Sweden. Verifierade medlemmar får en digital
              Based in Sweden-badge att använda på egna hemsidor, i
              mejlsignaturer m.m., och företagets logga kommer visas här bredvid
              andra molnleverantörer med svenska ägare och svensklagrad data.
            </div>
            <div>
              - Vi som är med i Based in Sweden förbinder oss att lagra
              information inom Sveriges gränser, och är stolta att kunna erbjuda
              molntjänster som är fria från amerikanska molnjättars insyn och
              inflytande.
            </div>
          </div>
        </div>
      </div>
      <div class="contact-container relative-parent">
        <div
          class="
            padding-side-10 padding-top-bottom-40
            centered
            f-25
            fw-600
            letter-spacing-05
            font-sora
          "
        >
          Kontakt
        </div>
        <div class="flex-row container-1200">
          <div
            class="
              flex-33 flex-row flex-dir-col flex-align-center
              padding-top-bottom-60 padding-side-60
              dark-blue-bg
            "
          >
            <img
              src="../assets/contact-mail.svg"
              class="margin-b-30 contact-icon"
              alt="Contact"
            />
            <div>
              <a href="mailto:info@basedinsweden.se">info@basedinsweden.se</a>
            </div>
          </div>
          <div
            class="
              flex-33 flex-row flex-dir-col flex-align-center
              padding-top-bottom-60 padding-side-60
              semi-dark-blue-bg
            "
          >
            <img
              src="../assets/contact-letter.svg"
              class="margin-b-30 contact-icon"
              alt="Contact"
            />
            <div>
              Based in Sweden
              <br />
              c/o Bahnhof AB
              <br />
              Box 7702
              <br />
              103 95 Stockholm
            </div>
          </div>
          <div
            class="
              flex-33 flex-row flex-dir-col flex-align-center
              padding-top-bottom-60 padding-side-60
              yellow-bg
            "
          >
            <img
              src="../assets/contact-info.svg"
              class="margin-b-30 contact-icon"
              alt="Contact"
            />
            <div class="darken-blue-text">
              Based in Sweden är grundat på initiativ av Bahnhof AB.
            </div>
          </div>
        </div>
      </div>
      <div class="grey-blue-bg padding-top-bottom-60">
        <div class="container-1200 flex-row">
          <div
            class="
              flex-row flex-justify-center flex-align-center
              company-logo-container
            "
            v-for="(logo, i) in companiesLogo"
            :key="'image_' + _uid + '_i_' + i"
          >
            <a :href="logo.url" target="_blank" rel="noopener">
              <img :src="getAssetsUrl(logo.name)" class="company-logo" alt="logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { companiesLogo } from "../entities/companiesLogo";

export default {
  name: "MainPage",
  data() {
    return {
      companiesLogo,
    };
  },
  methods: {
    getAssetsUrl(name) {
      return require(`../assets/${name}`);
    },
    initVideo(video) {
      let req = new XMLHttpRequest();
      this.videoWidth = window.innerWidth;
      this.videoHeight = window.innerHeight;
      req.open("GET", this.getAssetsUrl("clouds.mp4"), true);
      req.responseType = "blob";

      req.onload = function () {
        if (this.status === 200) {
          let videoBlob = this.response;
          let vid = URL.createObjectURL(videoBlob); // IE10+
          video.src = vid;
          video.play();
        }
      };
      req.send();
    },
    onscroll(advBlock) {
      let speed = 0.15;
      window.addEventListener("scroll", () => {
        if (window.innerWidth > 1025 && window.innerWidth < 1500) {
          let windowYOffset = window.pageYOffset,
            elBackgrounPos = "100% " + -windowYOffset * speed + "px";
          advBlock.style.backgroundPosition = elBackgrounPos;
        } else if (advBlock.attributes.style) {
          advBlock.removeAttribute("style");
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initVideo(this.$refs.videoBg);
      this.onscroll(this.$refs.advBlock);
    });
  },
};
</script>
