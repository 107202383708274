export const companiesLogo = [
    {
        name: 'bahnhof.svg',
        url: 'https://bahnhof.cloud/'
    },
    {
        name: 'optinet.png',
        url: 'https://www.optinet.se/'
    },
    {
        name: 'tripnet-color.png',
        url: 'https://tripnet.se/'
    },
    {
        name: 'zenconomy.png',
        url: 'https://www.zenconomy.se/'
    },
    {
        name: 'officenetwork.png',
        url: 'https://officenetwork.se/'
    },
    {
        name: 'netatonce.svg',
        url: 'https://www.netatonce.se/home'
    },
    {
        name: 'InfraCom_MS_Orange.png',
        url: 'https://infracom.se/'
    },
    {
        name: 'iniq_logo.png',
        url: 'https://iniq.se/'
    },
    {
        name: 'doxus.png',
        url: 'http://www.doxus.se/'
    },
    {
        name: 'Qlosr_logo_blue.png',
        url: 'https://qlosr.se/'
    },
    {
        name: 'OJCO_logo_2_pos-ai-2.png',
        url: 'https://www.ojco.se/'
    },
    {
        name: 'gns.png',
        url: 'https://www.gns.se/'
    },
    {
        name: 'datacom.png',
        url: 'https://www.datacom.se/'
    },
    {
        name: 'rackfish_logo_textsymbol.png',
        url: 'https://www.rackfish.com/'
    },
    {
        name: 'koneo72.png',
        url: 'https://www.koneo.se/'
    },
    {
        name: 'inleed-logo-alt.png',
        url: 'https://inleed.se/'
    },
    {
        name: 'ITK-logotype-web2.png',
        url: 'https://www.itkoncept.se/'
    },
    {
        name: 'werkander.png',
        url: 'http://www.wdt.nu/'
    },
    {
        name: 'seg_logotyp-2.png',
        url: 'https://www.svenskaenergigruppen.se/'
    },
    {
        name: 'appearo-logo.png',
        url: 'https://appearo.se/en/home/'
    },
    {
        name: 'bred-band.png',
        url: 'http://www.bredbandsson.se/foretag/'
    },
    {
        name: 'redcloud.png',
        url: 'https://www.redcloud.se/'
    },
    {
        name: 'beebyte.png',
        url: 'https://www.beebyte.se/'
    },
    {
        name: 'dendrit.png',
        url: 'https://dendritapp.com/'
    },
    {
        name: 'aditso.png',
        url: 'https://www.aditso.se/'
    },
    {
        name: 'egensajt.png',
        url: 'https://www.egensajt.se/'
    },
    {
        name: 'cygrids.png',
        url: 'https://www.cygrids.com/se'
    },
    {
        name: 'on1call.png',
        url: 'https://www.on1call.se/'
    },
    {
        name: 'zenbiz_group.png',
        url: 'https://zenbiz.se/'
    },
    {
        name: 'elastx.png',
        url: 'https://elastx.se/'
    },
    {
        name: 'binero.png',
        url: 'https://binero.se',
    },
    {
        name: 'qn.png',
        url: 'https://quicknet.se/',
    },
    {
        name: 'epm.png',
        url: 'https://www.epm.se/',
    },
    {
        name: 'run.png',
        url: 'https://www.run.se/',
    },
    {
        name: 'rymdweb.png',
        url: 'https://www.rymdweb.com/',
    },
    {
        name: 'centiljon.png',
        url: 'https://www.centiljon.se/',
    },
    {
        name: 'savecore.png',
        url: 'https://savecore.se/',
    },
    {
        name: 'mowic.png',
        url: 'https://www.mowic.se/',
    },
    {
        name: 'fiber-direkt.png',
        url: 'https://fiberdirekt.se',
    }
]
